<template>
    <div class="online-application">
        <div class="online-application-container">
            <div class="formbold-main-wrapper">
                <div class="formbold-form-wrapper">
                    <div class="title">
                        <h1 en="Application for Overdraft" sq="Aplikim për Overdraft" sr="Prijava za Prekoračenje">Aplikacija za Prekoračenje</h1>
                    </div>

                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" en="First Name" sq="Emri" sr="Ime">Emri</label>
                        <input type="text" v-model="formData.firstName" placeholder="Emri" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" en="Last Name" sq="Mbiemri" sr="Prezime">Mbiemri</label>
                        <input type="text" v-model="formData.lastName" placeholder="Mbiemri" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" en="Address" sq="Adresa" sr="Adresa">Adresa</label>
                        <input type="text" v-model="formData.address" placeholder="Adresa" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="phone" class="formbold-form-label" en="Phone Number" sq="Numri i telefonit" sr="Broj telefona">Numri i telefonit</label>
                        <input type="text" v-model="formData.phoneNumber" placeholder="Numri i telefonit" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="email" class="formbold-form-label" en="E-mail" sq="E-mail" sr="E-pošta">E-pošta</label>
                        <input type="email" v-model="formData.email" name="email" id="email" placeholder="E-mail" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" en="Your Employer Is" sq="Punëdhënësi juaj është" sr="Vaš poslodavac je">Punëdhënësi juaj është</label>
                        <select placeholder="Punëdhënësi juaj është" class="formbold-form-input" v-model="formData.company">
                            <option value="State Institution" selected="selected">Institucion shtetëror</option>
                            <option value="Public Enterprise">Ndërmarrje Publike</option>
                            <option value="Private Enterprise">Ndërmarrje Private</option>
                        </select>
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" en="Average Monthly Income" sq="Të ardhurat mesatare mujore" sr="Prosečni mesečni prihod">Të ardhurat mesatare mujore</label>
                        <input type="text" v-model="formData.averageIncome" placeholder="Të ardhurat mesatare mujore" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" en="Personal Number" sq="Numri personal" sr="Lični broj">Numri personal</label>
                        <input type="text" maxlength="10" v-model="formData.personalNumber" placeholder="Numri personal" class="formbold-form-input" />
                    </div>

                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" en="Do you accept salary in PriBank?" sq="A e pranoni pagën në PriBank?" sr="Da li prihvatate platu u PriBanku?">A e pranoni pagën në PriBank?</label>
                        <select placeholder="A e pranoni pagën në PriBank?" class="formbold-form-input" v-model="formData.salaryQuestion">
                            <option value="Yes" selected="selected">Po</option>
                            <option value="No">Jo</option>
                        </select>
                    </div>
                    <div class="file-upload">
                        <h3 en="Upload a copy of your ID" sq="Ngarko kopjen e letërnjoftimit" sr="Otpremite kopiju vaše lične karte">Ngarko kopjen e letërnjoftimit</h3>
                        <div class="drop_box" id="invoiceUpload1">
                            <header>
                                <h4 en="Select File here" sq="Zgjidhni skedarin këtu" sr="Izaberite fajl ovde">Zgjidhni skedarin këtu</h4>
                            </header>
                            <p en="Files Supported: PDF, TEXT, DOC, DOCX" sq="Skedarët e mbështetur: PDF, TEXT, DOC, DOCX" sr="Podržani formati fajla: PDF, TEXT, DOC, DOCX">Skedarët e mbështetur: PDF, TEXT, DOC, DOCX</p>
                            <input type="file" hidden accept=".doc,.docx,.pdf" id="fileID" style="display:none;" @change="onFileChange1">
                            <button class="btn" @click="focusIDFileInput()" en="Choose File" sq="Zgjidhni skedarin" sr="Izaberite fajl">Zgjidhni skedarin</button>
                        </div>
                        <div style="display: flex">
                            <p class="required">*</p><p class="note" en="Note: The allowed document for verification is a copy of your ID." sq="Vërejtje: Dokumenti i lejuar për verifikim është kopje e letërnjoftimit." sr="Napomena: Dozvoljeni dokument za verifikaciju je kopija vaše lične karte.">Vërejtje: Dokumenti i lejuar për verifikim është kopje e letërnjoftimit.</p>
                        </div>
                    </div>
                    <div class="file-upload">
                        <h3 en="Upload proof of income (account statement)" sq="Ngarko dëshmi mbi të hyrat (gjendjen e llogarisë)" sr="Otpremite dokaz o prihodima (izvod iz računa)">Ngarko dëshmi mbi të hyrat (gjendjen e llogarisë)</h3>
                        <div class="drop_box" id="invoiceUpload2">
                            <header>
                                <h4 en="Select File here" sq="Zgjidhni skedarin këtu" sr="Izaberite fajl ovde">Zgjidhni skedarin këtu</h4>
                            </header>
                            <p en="Files Supported: PDF, TEXT, DOC, DOCX" sq="Skedarët e mbështetur: PDF, TEXT, DOC, DOCX" sr="Podržani formati fajla: PDF, TEXT, DOC, DOCX">Skedarët e mbështetur: PDF, TEXT, DOC, DOCX</p>
                            <input type="file" hidden accept=".doc,.docx,.pdf" id="invoiceID" style="display:none;" @change="onFileChange2">
                            <button class="btn" @click="focusInvoiceFileInput()" en="Choose File" sq="Zgjidhni skedarin" sr="Izaberite fajl">Zgjidhni skedarin</button>
                        </div>
                        <div style="display: flex">
                            <p class="required">*</p><p class="note" en="Note: " sq="Vërejtje: " sr="Napomena: ">Vërejtje: </p>
                        </div>
                    </div>
                    <div class="file-upload">
                        <h3 en="Employment Contract" sq="Kontrata e punës" sr="Ugovor o radu">Kontrata e punës</h3>
                        <div class="drop_box" id="invoiceUpload3">
                            <header>
                                <h4 en="Select File here" sq="Zgjidhni skedarin këtu" sr="Izaberite fajl ovde">Zgjidhni skedarin këtu</h4>
                            </header>
                            <p en="Files Supported: PDF, TEXT, DOC, DOCX" sq="Skedarët e mbështetur: PDF, TEXT, DOC, DOCX" sr="Podržani formati fajla: PDF, TEXT, DOC, DOCX">Skedarët e mbështetur: PDF, TEXT, DOC, DOCX</p>
                            <input type="file" hidden accept=".doc,.docx,.pdf" id="fileContract" style="display:none;" @change="onFileChange3">
                            <button class="btn" @click="focusContractFileInput()" en="Choose File" sq="Zgjidhni skedarin" sr="Izaberite fajl">Zgjidhni skedarin</button>
                        </div>
                        <div style="display: flex">
                            <p class="required">*</p><p class="note" en="Note: " sq="Vërejtje: " sr="Napomena: ">Vërejtje: </p>
                        </div>
                    </div>
                    <div>
                        <div class="formbold-mb-5">
                            <label for="name" class="formbold-form-label" en="Branch where you want to apply" sq="Dega ku dëshironi të aplikoni" sr="Filijala gde želite da se prijavite">Dega ku dëshironi të aplikoni</label>
                            <select placeholder="Dega ku deshironi te aplikoni" class="formbold-form-input" v-model="formData.branch">
                                <option value="Prishtinë" selected="selected" sq="Prishtinë" en="Prishtinë" sr="Priština"></option>
                                <option value="Prishtinë 2" selected="selected" sq="Prishtinë - Dega 2" en="Prishtinë - Branch 2" sr="Priština - Filijala 2"></option>
                                <option value="FKosove" sq="Fushë Kosovë" en="Fushë Kosovë" sr="Fushë Kosovë"></option>
                                <option value="Ferizaj" sq="Ferizaj" en="Ferizaj" sr="Ferizaj"></option>
                                <option value="Prizren" sq="Prizren" en="Prizren" sr="Prizren"></option>
                                <option value="Gjilan" sq="Gjilan" en="Gjilan" sr="Gilan"></option>
                                <option value="Peje" sq="Pejë" en="Peja" sr="Peja"></option>
                                <option value="Gjakovë" sq="Gjakovë" en="Gjakovë" sr="Gjakovë"></option>
                                <option value="Mitrovicë" sq="Mitrovicë" en="Mitrovicë" sr="Mitrovicë"></option>
                                <option value="Drenas" sq="Drenas" en="Drenas" sr="Drenas"></option>
                                <option value="Suharekë" sq="Suharekë" en="Suharekë" sr="Suharekë"></option>
                                <option value="Lipjan" sq="Lipjan" en="Lipjan" sr="Lipjan"></option>
                                <option value="Vushtrri" sq="Vushtrri" en="Vushtrri" sr="Vushtrri"></option>
                            </select>
                            <br> <br />
                            <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
                            <label for="vehicle1" style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;' sq="Deklarata për përpunim të të dhënave personale" en="Declaration for Processing of Personal Data" sr="Izjava o obradi ličnih podataka"></label><br>
                            <br />
                            <p sq="Të dhënat personale të cilat kërkohen për aplikim online nga PriBank do të përpunohen në pajtim me Ligjin nr. 06/L-082 dhe Rregulloren e Bashkimit Europian 2016/679 (GDRP) dhe këto të dhëna do të përpunohen vetëm për qëllim të realizimit të këtij aplikacioni. Me klikimin e butonit “Pajtohem”, ju pajtoheni se të dhënat personale të lartë cekura të përpunohen nga PriBank për qëllimin e specifikuar, poashtu konfirmoni se këto të dhëna dhe dokumentet mbështetëse të ngarkuara nga ju janë të plota, të sakta dhe të vërteta."
                               en="Personal data required for online application by PriBank will be processed in accordance with Law no. 06/L-082 and Regulation of the European Union 2016/679 (GDRP) and these data will be processed only for the purpose of realizing this application. By clicking the 'I agree' button, you agree that the above personal data will be processed by PriBank for the specified purpose, and you also confirm that this data and the supporting documents uploaded by you are complete, correct and true."
                               sr="Lični podaci potrebni za onlajn prijavu od strane PriBank-a biće obrađeni u skladu sa Zakonom br. 06/L-082 i Uredbe Evropske unije 2016/679 (GDRP) i ovi podaci će se obrađivati samo u svrhu realizacije ove aplikacije. Klikom na dugme „Slažem se“, saglasni ste da će PriBank gore navedene lične podatke obrađivati u navedenu svrhu, a takođe potvrđujete da su ovi podaci i prateća dokumentacija koju ste uneli potpuni, tačni i istiniti."></p>
                            <br />
                            <p>
                                <i sq="Sqarim: Aplikimi përmes faqes sonë të internetit për produktin/shërbimin e kërkuar nuk nënkupton krijimin e marrëdhënie biznesore me PriBank. PriBank rezervon të drejtën që të ju kontaktoj dhe të kërkoj nga ju dokumente shtesë duke u bazuar në Ligjet dhe Rregulloret në fuqi. Vetëm pas kompletimit të të gjitha kërkesave të PriBank për produktin/shërbimin e kërkuar dhe pas nënshkrimit të marrëveshjes specifike ju mund të pranoni produktin/shërbimin e kërkuar."
                                   en="Clarification: Applying through our website for the requested product/service does not mean establishing a business relationship with PriBank. PriBank reserves the right to contact you and request additional documents from you based on the Laws and Regulations in force. Only after completing all PriBank requirements for the requested product/service and after signing the specific agreement can you accept the requested product/service."
                                   sr="Pojašnjenje: Podnošenje zahteva preko naše veb stranice za traženi proizvod/uslugu ne znači uspostavljanje poslovnog odnosa sa PriBank. PriBank zadržava pravo da vas kontaktira i zatraži dodatnu dokumentaciju na osnovu važećih zakona i propisa. Tek nakon ispunjavanja svih uslova PriBank-a za traženi proizvod/uslugu i nakon potpisivanja konkretnog ugovora možete prihvatiti traženi proizvod/uslugu."></i>
                            </p>
                        </div>
                        <button class="formbold-btn" @click="insertFormData()">Dërgo</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
import { externalScript } from "../../assets/external";
    export default {
        data() {
            return {
                formData: {
                    firstName: '',
                    lastName: '',
                    address: '',
                    phoneNumber: '',
                    email: '',
                    company: '',
                    averageIncome: '',
                    personalNumber: '',
                    salaryQuestion: '',
                    branch: '',
                    idFile:'',
                    balanceFile:'',
                    contractFile:'',
                },
                formID: 0
            }
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias", "insertComplaintSuggestionForm"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
            async insertFormData() {
                if (!this.controlEmail(this.formData.email)) {
                    externalScript.popup(" ", "Email juaj është gabim!")
                    return;
                }
                if (this.formData.firstName == ""
                    || this.formData.lastName == ""
                    || this.formData.address == ""
                    || this.formData.phoneNumber == ""
                    || this.formData.email == ""
                    || this.formData.company == ""
                    || this.formData.avgIncomes == ""
                    || this.formData.personalNumber == ""
                    || this.formData.salaryQuestion == ""
                    || this.formData.idFile == ""
                    || this.formData.balanceFile == ""
                    || this.formData.contractFile == ""
                    || this.formData.branch == ""
                )
                {
                    externalScript.popup("Gabim", "Të gjitha fushat duhet të plotësohen.")
                    return;
                }

                var bodyFormData = new FormData();
                bodyFormData.append('firstName', this.formData.firstName);
                bodyFormData.append('lastName', this.formData.lastName);
                bodyFormData.append('address', this.formData.address);
                bodyFormData.append('phoneNumber', this.formData.phoneNumber);
                bodyFormData.append('email', this.formData.email);
                bodyFormData.append('company', this.formData.company);
                bodyFormData.append('avgIncomes', this.formData.averageIncome);
                bodyFormData.append('personalNumber', this.formData.personalNumber);
                bodyFormData.append('salaryQuestion', this.formData.salaryQuestion);
                bodyFormData.append('idFile', this.formData.idFile);
                bodyFormData.append('balanceFile', this.formData.balanceFile);
                bodyFormData.append('contractFile', this.formData.contractFile);
                bodyFormData.append('applicationBranch', this.formData.branch);

                switch (this.formData.branch) {
                    case 'Ferizaj':
                        this.formID = 545;
                        break;
                    case 'FKosove':
                        this.formID = 696;
                        break;
                    case 'Prizren':
                        this.formID = 667;
                        break;
                    case 'Prishtinë 2':
                        this.formID = 902;
                        break;
                    case 'Gjilan':
                        this.formID = 900;
                        break;
                    case 'Peje':
                        this.formID = 901;
                        break;
                    case 'Drenas':
                        this.formID = 1104;
                        break;
                    case 'Gjakovë':
                        this.formID = 1102;
                        break;
                    case 'Mitrovicë':
                        this.formID = 1103;
                        break;
                    case 'Suharekë':
                        this.formID = 1145;
                        break;
                    case 'Lipjan':
                        this.formID = 1157;
                        break;
                    case 'Vushtrri':
                        this.formID = 1177;
                        break;
                    default:
                        this.formID = 11;
                        break;
                }

                bodyFormData.append('_wpcf7_unit_tag', this.formID);

                await this.insertComplaintSuggestionForm({
                    id: this.formID,
                    formData: bodyFormData,
                    successCallback: () => {
                        this.clearFormData();
                        externalScript.popup(" ", "Aplikimi juaj është kryer me sukses! <br /> Për detaje të mëtutjeshme do të kontaktoheni nga Këshilltarët tanë për klientë!")
                    }
                });
            },
            clearFormData() {
                this.formData.firstName = '';
                this.formData.lastName = '';
                this.formData.address = '';
                this.formData.phoneNumber = '';
                this.formData.email = '';
                this.formData.company = '';
                this.formData.averageIncome = '';
                this.formData.personalNumber = '';
                this.formData.salaryQuestion = '';
                this.formData.branch = '';
                this.formData.idFile = '';
                this.formData.balanceFile = '';
                this.formData.contractFile = '';
            },
            focusIDFileInput() {
                document.getElementById("fileID").click()
            },
            focusInvoiceFileInput() {
                document.getElementById("invoiceID").click()
            },
            focusContractFileInput() {
                document.getElementById("fileContract").click()
            },
            controlEmail(email) {
                const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                return emailRegex.test(email);
            },
            onFileChange1(e) {
                const dropArea = document.getElementById("invoiceUpload1")
                var fileName = e.target.files[0].name;
                this.formData.idFile = e.target.files[0];
                let filedata = `<p>${fileName}</p>`;
                dropArea.innerHTML = filedata;
            },
            onFileChange2(e) {
                const dropArea = document.getElementById("invoiceUpload2")
                var fileName = e.target.files[0].name;
                this.formData.balanceFile = e.target.files[0];
                let filedata = `<p>${fileName}</p>`;
                dropArea.innerHTML = filedata;
            },
            onFileChange3(e) {
                const dropArea = document.getElementById("invoiceUpload3")
                var fileName = e.target.files[0].name;
                this.formData.contractFile = e.target.files[0];
                let filedata = `<p>${fileName}</p>`;
                dropArea.innerHTML = filedata;
            }
            // onHeaderChange(e) {
            //     let files = e.target.files || e.dataTransfer.files;
            //     if (!files.length) {
            //         document.getElementById("newsHeaderImageLabel").value = "Click here";
            //         return;
            //     }
            //     let reader = new FileReader();
            //     reader.onload = a => {
            //         this.newsItem.header = a.target.result.split("base64,")[1];
            //         document.getElementById("newsHeaderImageLabel").value = files[0].name.split('\\').pop().split('/').pop();
            //     };
            //     reader.readAsDataURL(files[0]);
            // },
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getAboutUs() {
                return this.getCategories.find(x => x.slug === 'online-applications')
            },
            getAboutUsPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getAboutUs.id)
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            },
        },
        async mounted() {
            externalScript.reloadLanguage();
        },
    };
</script>

<style>
    input[type="checkbox"] {
        accent-color: #5F4393;
    }

    @import "../../assets/styles/individual-online-application.css";
</style>