<template>
    <div class="online-application">
        <div class="online-application-container">
            <div class="formbold-main-wrapper">
                <div class="formbold-form-wrapper">
                    <div class="title">
                        <h1 sq="Aplikim për E-Banking" en="E-Banking Application" sr="Prijava za E-Banking"></h1>
                    </div>

                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" sq="Emri" en="First Name" sr="Ime"></label>
                        <input type="text" v-model="formData.firstName" placeholder="Emri" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" sq="Mbiemri" en="Last Name" sr="Prezime"></label>
                        <input type="text" v-model="formData.lastName" placeholder="Mbiemri" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="phone" class="formbold-form-label" sq="Numri i telefonit" en="Phone Number" sr="Broj telefona"></label>
                        <input type="text" v-model="formData.phoneNumber" placeholder="Numri i telefonit" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="phone" class="formbold-form-label" sq="Numri i llogarisë rrjedhëse" en="Current Account Number" sr="Broj tekućeg računa"></label>
                        <input type="text" v-model="formData.accountNumber" placeholder="Numri i llogarisë rrjedhëse" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="email" class="formbold-form-label" sq="E-mail" en="E-mail" sr="E-pošta"></label>
                        <input type="email" v-model="formData.email" name="email" id="email" placeholder="E-mail" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" sq="Numri personal" en="Personal Number" sr="Lični broj"></label>
                        <input type="text" maxlength="10" v-model="formData.personalNumber" placeholder="Numri personal" class="formbold-form-input" />
                    </div>
                    <div class="formbold-mb-5">
                        <label for="name" class="formbold-form-label" sq="Dega ku deshironi te aplikoni" en="Branch where you want to apply" sr="Filijala na kojoj želite da aplicirate"></label>
                        <select placeholder="Dega ku deshironi te aplikoni" class="formbold-form-input" v-model="formData.applicationBranch">
                            <option value="Prishtinë" selected="selected" sq="Prishtinë" en="Prishtinë" sr="Priština"></option>
                            <option value="FKosove" sq="Fushë Kosovë" en="Fushë Kosovë" sr="Fushë Kosovë"></option>
                            <option value="Ferizaj" sq="Ferizaj" en="Ferizaj" sr="Ferizaj"></option>
                            <option value="Prizren" sq="Prizren" en="Prizren" sr="Prizren"></option>
                            <option value="Gjilan" sq="Gjilan" en="Gjilan" sr="Gilan"></option>
                            <option value="Peje" sq="Pejë" en="Peja" sr="Peja"></option>
                            <option value="Gjakovë" sq="Gjakovë" en="Gjakovë" sr="Gjakovë"></option>
                            <option value="Mitrovicë" sq="Mitrovicë" en="Mitrovicë" sr="Mitrovicë"></option>
                            <option value="Drenas" sq="Drenas" en="Drenas" sr="Drenas"></option>
                            <option value="Suharekë" sq="Suharekë" en="Suharekë" sr="Suharekë"></option>
                            <option value="Lipjan" sq="Lipjan" en="Lipjan" sr="Lipjan"></option>
                            <option value="Vushtrri" sq="Vushtrri" en="Vushtrri" sr="Vushtrri"></option>
                        </select>
                    </div>
                    <br> <br />
                    <input type="checkbox" id="vehicle1" name="vehicle1" value="Bike">
                    <label for="vehicle1" style='margin-top:0in;margin-right:0in;margin-bottom:8.0pt;margin-left:0in;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;' sq="Deklarata për përpunim të të dhënave personale" en="Declaration for Processing of Personal Data" sr="Izjava o obradi ličnih podataka"></label><br>
                    <br />
                    <p sq="Të dhënat personale të cilat kërkohen për aplikim online nga PriBank do të përpunohen në pajtim me Ligjin nr. 06/L-082 dhe Rregulloren e Bashkimit Europian 2016/679 (GDRP) dhe këto të dhëna do të përpunohen vetëm për qëllim të realizimit të këtij aplikacioni. Me klikimin e butonit “Pajtohem”, ju pajtoheni se të dhënat personale të lartë cekura të përpunohen nga PriBank për qëllimin e specifikuar, poashtu konfirmoni se këto të dhëna dhe dokumentet mbështetëse të ngarkuara nga ju janë të plota, të sakta dhe të vërteta."
                       en="Personal data required for online application by PriBank will be processed in accordance with Law no. 06/L-082 and Regulation of the European Union 2016/679 (GDRP) and these data will be processed only for the purpose of realizing this application. By clicking the 'I agree' button, you agree that the above personal data will be processed by PriBank for the specified purpose, and you also confirm that this data and the supporting documents uploaded by you are complete, correct and true."
                       sr="Lični podaci potrebni za onlajn prijavu od strane PriBank-a biće obrađeni u skladu sa Zakonom br. 06/L-082 i Uredbe Evropske unije 2016/679 (GDRP) i ovi podaci će se obrađivati samo u svrhu realizacije ove aplikacije. Klikom na dugme „Slažem se“, saglasni ste da će PriBank gore navedene lične podatke obrađivati u navedenu svrhu, a takođe potvrđujete da su ovi podaci i prateća dokumentacija koju ste uneli potpuni, tačni i istiniti."></p>
                    <br />
                    <p>
                        <i sq="Sqarim: Aplikimi përmes faqes sonë të internetit për produktin/shërbimin e kërkuar nuk nënkupton krijimin e marrëdhënie biznesore me PriBank. PriBank rezervon të drejtën që të ju kontaktoj dhe të kërkoj nga ju dokumente shtesë duke u bazuar në Ligjet dhe Rregulloret në fuqi. Vetëm pas kompletimit të të gjitha kërkesave të PriBank për produktin/shërbimin e kërkuar dhe pas nënshkrimit të marrëveshjes specifike ju mund të pranoni produktin/shërbimin e kërkuar."
                           en="Clarification: Applying through our website for the requested product/service does not mean establishing a business relationship with PriBank. PriBank reserves the right to contact you and request additional documents from you based on the Laws and Regulations in force. Only after completing all PriBank requirements for the requested product/service and after signing the specific agreement can you accept the requested product/service."
                           sr="Pojašnjenje: Podnošenje zahteva preko naše veb stranice za traženi proizvod/uslugu ne znači uspostavljanje poslovnog odnosa sa PriBank. PriBank zadržava pravo da vas kontaktira i zatraži dodatnu dokumentaciju na osnovu važećih zakona i propisa. Tek nakon ispunjavanja svih uslova PriBank-a za traženi proizvod/uslugu i nakon potpisivanja konkretnog ugovora možete prihvatiti traženi proizvod/uslugu."></i>
                    </p>
                    <div>
                        <button class="formbold-btn" @click="insertFormData()" sq="Dërgo" en="Submit" sr="Pošalji"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapGetters } from "vuex";
import { externalScript } from "../../assets/external";
    export default {
        data() {
            return {
                formData: {
                    firstName: '',
                    lastName: '',
                    phoneNumber: '',
                    email: '',
                    personalNumber: '',
                    accountNumber: '',
                    applicationBranch: '',
                },
                formID: 0
            }
        },
        methods: {
            ...mapActions(["fetchCategories", "fetchPosts", "fetchMedia", "fetchMedias", "insertComplaintSuggestionForm"]),
            getPostMedia(id) {
                let media = this.getMedias.find(x => x.id === id);
                return media?.source_url;
            },
            async insertFormData() {
                if (!this.controlEmail(this.formData.email)) {
                    externalScript.popup(" ", "Email juaj është gabim!")
                    return;
                }
                if (this.formData.firstName == ""
                    || this.formData.lastName == ""
                    || this.formData.phoneNumber == ""
                    || this.formData.email == ""
                    || this.formData.accountNumber == ""
                    || this.formData.personalNumber == ""
                    || this.formData.applicationBranch == "") {
                    externalScript.popup("Gabim", "Të gjitha fushat duhet të plotësohen.")
                    return;
                }
                var bodyFormData = new FormData();
                bodyFormData.append('firstName', this.formData.firstName);
                bodyFormData.append('lastName', this.formData.lastName);
                bodyFormData.append('phoneNumber', this.formData.phoneNumber);
                bodyFormData.append('email', this.formData.email);
                bodyFormData.append('personalNumber', this.formData.personalNumber);
                bodyFormData.append('accountNumber', this.formData.accountNumber);
                bodyFormData.append('applicationBranch', this.formData.applicationBranch);


                switch (this.formData.branch) {
                    case 'Ferizaj':
                        this.formID = 540;
                        break;
                    case 'FKosove':
                        this.formID = 697;
                        break;
                    case 'Prizren':
                        this.formID = 671;
                        break;
                    case 'Gjilan':
                        this.formID = 886;
                        break;
                    case 'Peje':
                        this.formID = 887;
                        break;
                    case 'Drenas':
                        this.formID = 1113;
                        break;
                    case 'Gjakovë':
                        this.formID = 1111;
                        break;
                    case 'Mitrovicë':
                        this.formID = 1112;
                        break;
                    case 'Suharekë':
                        this.formID = 1146;
                        break;
                    case 'Lipjan':
                        this.formID = 1153;
                        break;
                    case 'Vushtrri':
                        this.formID = 1172;
                        break;
                    default:
                        this.formID = 8;
                        break;
                }

                bodyFormData.append('_wpcf7_unit_tag', this.formID);

                await this.insertComplaintSuggestionForm({
                    id: this.formID,

                    formData: bodyFormData,
                    successCallback: () => {
                        this.clearFormData();
                        externalScript.popup(" ", "Aplikimi juaj është kryer me sukses! <br /> Për detaje të mëtutjeshme do të kontaktoheni nga Këshilltarët tanë për klientë!")
                    }
                });
            },
            clearFormData() {
                this.formData.firstName = '';
                this.formData.lastName = '';
                this.formData.phoneNumber = '';
                this.formData.email = '';
                this.formData.personalNumber = '';
                this.formData.accountNumber = '';
                this.formData.applicationBranch = '';
            },
        },
        computed: {
            ...mapGetters(["getCategories", "getPosts", "getMedia", "getMedias"]),
            getAboutUs() {
                return this.getCategories.find(x => x.slug === 'online-applications')
            },
            getAboutUsPosts() {
                return this.sortedPosts.filter(x => x.categories[0] == this.getAboutUs.id)
            },
            controlEmail(email) {
                const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
                return emailRegex.test(email);
            },
            sortedPosts() {
                return [...this.getPosts].sort((a, b) => a.id - b.id)
            },
        },
        async mounted() {
            externalScript.reloadLanguage();
        },
    };
</script>

<style>
    @import "../../assets/styles/individual-online-application.css";
</style>