<template>
    <div style="padding-left: 15%; padding-right: 10%; margin-top: 2%">
        <div class="tabs-container">
            <div class="tabs">
                <button @click="setStep(1)" :class="[step == 1 ? 'activeStep' : '']" style="border-right: none;">Degët</button>
                <button @click="setStep(2)" :class="[step == 2 ? 'activeStep' : '']">Bankomatet</button>
            </div>
        </div>
        <div v-if="step == 1">
            <div id="data-Map" style="display: grid; padding-bottom: 30px; align-content: center; grid-template-columns: repeat(4, 1fr); grid-template-rows: repeat(2, 1fr); gap: 10px; justify-content: center; margin-top: 4%; grid-row-gap: 50px; ">
                <div v-for="bank in banks" :key="bank" style="cursor: pointer;" class="cardwrapp" :class="[bankId == bank.Id ? 'activeCard' : '']" @click="openMap(bank.Id)">
                    <div class="bank-descriptions" style="padding: 25px;">
                        <div style="width: 300px;" class="bank-branches">
                            <div class="logo">
                                <img src="../../../assets/images/logo.svg" width="189" height="44" />
                            </div>
                            <label style="font-weight: 600"></label><p>{{bank.Dega}}</p>
                            <label style="font-weight: 600"></label><p>{{bank.Address}}</p>
                            <label style="font-weight: 600"></label><p>{{bank.Tel}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <iframe v-show="bankId == 1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.945901779938!2d21.14772927654995!3d42.639760717384384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549febc26efe4d%3A0x356437d0509d1a29!2sPriBank!5e1!3m2!1sen!2s!4v1696936227211!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                <iframe v-show="bankId == 2" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d441.5094975662902!2d21.15451565210961!3d42.65855722825683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDM5JzMwLjMiTiAyMcKwMDknMTcuMSJF!5e1!3m2!1sen!2s!4v1696936101273!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                <iframe v-show="bankId == 3" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1253.7348225288727!2d21.11024070547033!3d42.6387617891107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDM4JzIwLjEiTiAyMcKwMDYnNDIuMiJF!5e1!3m2!1sen!2s!4v1696936056851!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                <iframe v-show="bankId == 4" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d609.8362319940173!2d20.292347685075224!3d42.65721888877788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDM5JzI3LjAiTiAyMMKwMTcnMzMuMSJF!5e1!3m2!1sen!2s!4v1696935736691!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                <iframe v-show="bankId == 5" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1253.0407904907415!2d21.466225460298038!3d42.46689481254228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDI4JzAxLjUiTiAyMcKwMjgnMDMuNSJF!5e1!3m2!1sen!2s!4v1696935475101!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                <iframe v-show="bankId == 6" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1769.1103511097494!2d21.15472899729165!3d42.37125152376175!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDIyJzE3LjAiTiAyMcKwMDknMjAuOCJF!5e1!3m2!1sen!2s!4v1696935247524!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                <iframe v-show="bankId == 7" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d909.6602687897393!2d20.72616754718636!3d42.2169020344842!2m3!1f66.1875!2f0!3f0!3m2!1i1024!2i768!4f35!3m2!1m1!2zNDLCsDEyJzU5LjEiTiAyMMKwNDMnMzUuMCJF!5e1!3m2!1sen!2s!4v1696935072404!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                <iframe v-show="bankId == 8" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1253.7348225288727!2d21.11024070547033!3d42.6387617891107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDM4JzIwLjEiTiAyMcKwMDYnNDIuMiJF!5e1!3m2!1sen!2s!4v1696936056851!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                <iframe v-show="bankId == 9" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2574.853854141451!2d20.426510875450557!3d42.38353493361919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDIzJzAwLjciTiAyMMKwMjUnNDQuNyJF!5e1!3m2!1sen!2s!4v1716880699044!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="bankId == 10" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2554.034336800013!2d20.869694475479267!3d42.888746701538025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDUzJzE5LjUiTiAyMMKwNTInMjAuMiJF!5e1!3m2!1sen!2s!4v1716880484780!5m2!1sen!2s"  style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="bankId == 11" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2564.908067177509!2d20.892697975464316!3d42.62548571829243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDM3JzMxLjciTiAyMMKwNTMnNDMuMCJF!5e1!3m2!1sen!2s!4v1716880388554!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="bankId == 12" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2569.1977174898175!2d21.119256075458406!3d42.52126822490256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDMxJzE2LjYiTiAyMcKwMDcnMTguNiJF!5e1!3m2!1sen!2s!4v1716880275821!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="bankId == 13" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2575.707699177526!2d20.829406775449495!3d42.36271133493502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDIxJzQ1LjgiTiAyMMKwNDknNTUuMSJF!5e1!3m2!1sen!2s!4v1716880076559!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="bankId == 14" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3417.8378675018757!2d20.963718449394104!3d42.82407894737839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDQ5JzIzLjAiTiAyMMKwNTcnNTIuNiJF!5e1!3m2!1sen!2s!4v1719903470616!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>
        </div>
        <div v-if="step == 2">
            <div id="data-Map" style="display: grid; padding-bottom: 30px; align-content: center; grid-template-columns: repeat(4, 1fr); grid-template-rows: repeat(2, 1fr); gap: 10px; justify-content: center; margin-top: 4%; grid-row-gap: 50px; ">
                <div v-for="bank in atms" :key="bank" style="cursor: pointer;" class="cardwrapp" :class="[atmId == bank.Id ? 'activeCard' : '']" @click="openAtmMap(bank.Id)">
                    <div class="bank-descriptions" style="padding: 25px;">
                        <div style="width: 300px;" class="bank-branches">
                            <div class="logo">
                                <img src="../../../assets/images/logo.svg" width="189" height="44" />
                            </div>
                            <label style="font-weight: 600"></label><p>{{bank.Dega}}</p>
                            <label style="font-weight: 600"></label><p>{{bank.Address}}</p>
                            <label style="font-weight: 600"></label><p>{{bank.Tel}}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <iframe v-show="atmId == 1" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3557.945901779938!2d21.14772927654995!3d42.639760717384384!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13549febc26efe4d%3A0x356437d0509d1a29!2sPriBank!5e1!3m2!1sen!2s!4v1696936227211!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="atmId == 2" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d441.5094975662902!2d21.15451565210961!3d42.65855722825683!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDM5JzMwLjMiTiAyMcKwMDknMTcuMSJF!5e1!3m2!1sen!2s!4v1696936101273!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="atmId == 3" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1253.7348225288727!2d21.11024070547033!3d42.6387617891107!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDM4JzIwLjEiTiAyMcKwMDYnNDIuMiJF!5e1!3m2!1sen!2s!4v1696936056851!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="atmId == 4" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d609.8362319940173!2d20.292347685075224!3d42.65721888877788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDM5JzI3LjAiTiAyMMKwMTcnMzMuMSJF!5e1!3m2!1sen!2s!4v1696935736691!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="atmId == 5" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1253.0407904907415!2d21.466225460298038!3d42.46689481254228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDI4JzAxLjUiTiAyMcKwMjgnMDMuNSJF!5e1!3m2!1sen!2s!4v1696935475101!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="atmId == 6" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2574.853854141451!2d20.426510875450557!3d42.38353493361919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDIzJzAwLjciTiAyMMKwMjUnNDQuNyJF!5e1!3m2!1sen!2s!4v1716880699044!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="atmId == 7" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2554.034336800013!2d20.869694475479267!3d42.888746701538025!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDUzJzE5LjUiTiAyMMKwNTInMjAuMiJF!5e1!3m2!1sen!2s!4v1716880484780!5m2!1sen!2s"  style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="atmId == 8" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2564.908067177509!2d20.892697975464316!3d42.62548571829243!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDM3JzMxLjciTiAyMMKwNTMnNDMuMCJF!5e1!3m2!1sen!2s!4v1716880388554!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="atmId == 9" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2569.1977174898175!2d21.119256075458406!3d42.52126822490256!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDMxJzE2LjYiTiAyMcKwMDcnMTguNiJF!5e1!3m2!1sen!2s!4v1716880275821!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="atmId == 10" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d2575.707699177526!2d20.829406775449495!3d42.36271133493502!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDIxJzQ1LjgiTiAyMMKwNDknNTUuMSJF!5e1!3m2!1sen!2s!4v1716880076559!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="atmId == 11" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3417.8378675018757!2d20.963718449394104!3d42.82407894737839!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDQ5JzIzLjAiTiAyMMKwNTcnNTIuNiJF!5e1!3m2!1sen!2s!4v1719903470616!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                <iframe v-show="atmId == 12" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d609.8362319940173!2d20.292347685075224!3d42.65721888877788!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDM5JzI3LjAiTiAyMMKwMTcnMzMuMSJF!5e1!3m2!1sen!2s!4v1696935736691!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                <iframe v-show="atmId == 13" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d1253.0407904907415!2d21.466225460298038!3d42.46689481254228!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zNDLCsDI4JzAxLjUiTiAyMcKwMjgnMDMuNSJF!5e1!3m2!1sen!2s!4v1696935475101!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
                <iframe v-show="atmId == 14" src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d909.6602687897393!2d20.72616754718636!3d42.2169020344842!2m3!1f66.1875!2f0!3f0!3m2!1i1024!2i768!4f35!3m2!1m1!2zNDLCsDEyJzU5LjEiTiAyMMKwNDMnMzUuMCJF!5e1!3m2!1sen!2s!4v1696935072404!5m2!1sen!2s" style="border: 0; width: 100%; height: 50vh;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade" ></iframe>
            </div>
        </div>
    </div>
</template>

<script>
    import { Banks } from '../../../assets/Banks';


    export default {
        data() {
            return {
                step: 1,
                bankId: 0,
                atmId: 0,
            }
        },
        computed: {
            banks() {
                return Banks.banks.PriBank;
            },
            atms() {
                return Banks.atms.PriBank;
            },
        },
        methods: {
            setStep(i) {
                this.step = i;
            },
            openMap(i) {
                this.bankId = i;
            },
            openAtmMap(i) {
                this.atmId = i;
            }
        },
        mounted() {
            this.openMap(1);
            this.openAtmMap(1);
        }
    }
</script>

<style>
    .tabs {
        overflow: hidden;
        margin-top: 20px;
        margin-bottom: 48px;
    }

    .tabs-container {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .tabs button {
        width: 150px;
        font-size: 1.1rem;
        float: left;
        cursor: pointer;
        padding: 12px 24px;
        transition: background-color .2s;
        border: 1px solid #ccc;
        background-color: #fff;
        border-radius: 2px 2px 0 0;
        font-weight: 700;
    }

    .activeCard {
        -webkit-box-shadow: 0px 0px 5px 0px rgba(207,207,207,0.73);
        -moz-box-shadow: 0px 0px 5px 0px rgba(207,207,207,0.73);
        box-shadow: 0px 0px 5px 0px rgba(207,207,207,0.73);
        transition: 0.3s ease-in;
    }
    .activeStep {
        background-color: #624599 !important;
        color: white;
        transition: 0.3s ease-in;
    }
    .cardwrapp {
        transition: 0.3s ease-in;
    }
    .cardwrapp:hover {
        -webkit-box-shadow: 0px 0px 5px 0px rgba(207,207,207,0.73);
        -moz-box-shadow: 0px 0px 5px 0px rgba(207,207,207,0.73);
        box-shadow: 0px 0px 5px 0px rgba(207,207,207,0.73);
        transition: 0.3s ease-in;
    }
</style>